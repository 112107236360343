@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Spline+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    cursor: none;
    user-select: none;
}

body {
    background: white;
}

@layer utilities {
    .text-gradient {
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@font-face {
    font-family: 'RECKLESSNEUE';
    src: url('./fonts/RecklessNeueTRIAL-Medium.woff2') format('woff2'),
        url('./fonts/RecklessNeueTRIAL-Medium.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'ROOBERT';
    src: url('./fonts/RoobertTRIAL-Regular.woff2') format('woff2'),
        url('./fonts/RoobertTRIAL-Regular.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'gtRegular';
    src: url('./fonts/GTWalsheimRegular.woff2') format('woff2'),
        url('./fonts/GTWalsheimRegular.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'gtMedium';
    src: url('./fonts/GTWalsheim-Medium.woff2') format('woff2'),
        url('./fonts/GTWalsheim-Medium.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'gtBold';
    src: url('./fonts/GTWalsheim-Bold.woff2') format('woff2'),
        url('./fonts/GTWalsheim-Bold.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'moskSemi';
    src: url('./fonts/MoskSemi-Bold600.woff2') format('woff2'),
        url('./fonts/MoskSemi-Bold600.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'moskUltra';
    src: url('./fonts/MoskUltra-Bold900.woff2') format('woff2'),
        url('./fonts/MoskUltra-Bold900.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

@font-face {
    font-family: 'SplineSans';
    src: url('./fonts/SplineSans-Regular.woff2') format('woff2'),
        url('./fonts/SplineSans-Regular.woff') format('woff');
    font-display: swap;
    crossorigin: anonymous;
}

.text-shadow-md {
    text-shadow: 0 8px 4px rgba(0, 0, 0, 0.12);
}

::selection {
    color: black;
    background: #6dfacd;
}

.cursor {
    background-color: white;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    mix-blend-mode: difference;
}